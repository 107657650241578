export default class regex {
  static readonly NAME_REGEX = /^[a-zA-Z\u0590-\u05fe_ ]{2,20}$/;
  static readonly EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}/u;
  static readonly PHONE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/u;
  static readonly ZIPCODE_REGEX = /(?=^[0-9a-zA-Z-]{4,10}$)(?=^[A-Za-z0-9]+-?[A-Za-z0-9]+$)/gm;
  static readonly STREET_AND_NUMBER_REGEX = /^[\w (\u0590-\u05fe),]{2,45}/;
  static readonly FLOOR_AND_APARTMENT_REGEX = /^[\w(\u0590-\u05fe) ,-/]{0,45}$/;
  //check video id from url
  static readonly YOUTUBEiD_REGEX =
    '/^.*((youtu.be/)|(v/)|(/u/w/)|(embed/)|(watch?))??v?=?([^#&?]*).*/';
  //gat video from youtube.
  static readonly YOUTUBE_REGEX =
    '/^.*((youtu.be/)|(v/)|(/u/w/)|(embed/)|(watch?))??v?=?([^#&?]*).*/';
  // validE164
  static readonly E164_REGEX = /^[+]?[1-9]\d{1,14}$/u;
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //  // decimalNumberValidator
  //  const forbidden = !control.value.toString().match(/^[-+]?\d+(\.\d+)?$/);
  //  // decimalNumberValidator
  //      /^[-+]?\d+(\.\d+)?$/

  //  // numberValidator
  //  const forbidden = !control.value.toString().match(/^[-+]?[1-9]\d*\.?[0]*$/);

  //  // numberValidator
  //      /^ [-+] ? [1 - 9]\d *\.?[0] * $ /
}
